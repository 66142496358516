.search-wrapper
    position fixed
    top 30px
    width 100%
    padding-right 5%
    padding-left 5%
    transform translateY(-200px)
    z-index 19
    &.active
        transform translateY(0)

.search-form
    position relative
    top 0
    width 100%
    transform translateX(-200px)
    transition all 200ms 100ms cubic-bezier(0,0.6,0.4,1)
    opacity 0
    z-index 19
    .search-field
        width 100%
        height rem(40px)
        line-height rem(30px)
        mainFont(300)
        font-size rem(20px)
        color primaryDark
        background-color accentDark
        border 0
        border-radius rem(20px)
        padding rem(5px) rem(25px)
        outline 0
        &:focus
            outline 0
    &.active
        top 0
        transform translateX(0)
        opacity 1
    .search-list
        position absolute
        width 100%
        mainFont(300)
        display none
        padding 0 rem(20px)
        margin rem(20px) 0
        list-style-type none
        .active &
            display block
        +below(cut)
            height 380px
            overflow scroll
        .entry-date
            float right
            display none
            font-size rem(14px)
            +above(cut)
                display inline
        .entry-category
            text-transform uppercase
            background-color themeColor
            margin-right rem(5px)
            height rem(20px)
            border-radius rem(10px)
            font-size rem(12px)
            padding rem(2px) rem(8px)
            color #ffffff
        a
            color accentDark
            text-decoration none
            display block
            padding rem(15px) 0
            width 100%
            border-bottom 1px solid darken(accentDark, 80%)
            transition all .3s
            line-height rem(25px)
            &:hover
                color themeColor
                .entry-category
                    color #ffffff
    .icon-remove-sign
        position absolute
        top 0
        right 0
        display block
        width rem(26px)
        height rem(26px)
        padding rem(5px)
        fill accentDark
        background-color themeColor
        border-radius 13px
        margin rem(7px)
        cursor pointer
        outline 0
        z-index 1
        
.search-overlay
    overflow hidden
    &:after
        position fixed
        top 0
        left 0
        width 100%
        height 100%
        min-height 100%
        background-color primaryDark
        content ''
        z-index 18
