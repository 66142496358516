a
  -webkit-tap-highlight-color: rgba(0,0,0,0);

mainFont(weight = 700)
  font-family 'Titillium Web', 'Helvetica Neue', Helvetica, sans-serif
  font-weight weight
  font-style normal

h1,h2,h3,h4
  mainFont()

.text-center
  text-align center
