.author
    background lightGray
    display table
    width 100%
    padding rem(40px) 0
    margin rem(40px) 0
    +above(cut)
        padding rem(50px) 0
    .details
        margin auto
        max-width rem(800px)
        padding 0 rem(20px)
        +below(cut)
            text-align center
    svg
        size(25,25)
        margin-right rem(10px)
    a
        fill darken(lightGray, 30%)
        text-decoration none
        border none
        transition all .3s
        &:hover
            fill themeColor
    img
        width rem(180px)
        border-radius 50%
        display block
        margin auto
        +above(cut)
            float left
            margin-right 3.125rem
    .def
        color gray
        font-size rem(18px)
        mainFont(300)
        margin rem(10px)
        +above(cut)
            margin 0
    .name
        margin 0
        a
            mainFont()
            text-decoration none
            color black
            font-size rem(30px)
            &:hover
                color texts
            +above(cut)
                font-size rem(45px)
    .desc
        mainFont(300)
        margin rem(10px)
        font-size rem(16px)
        line-height rem(25px)
        +above(cut)
            font-size rem(18px)
