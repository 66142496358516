.contact-form
    mainFont()
    padding rem(10px)
    fieldset
        border none
        font-weight normal
    input[type="text"], input[type="email"], textarea
        box-sizing border-box
        outline none
        display block
        color #333333
        width 100%
        padding 7px
        border none
        border-bottom 1px solid #ddd
        margin-bottom 10px
        font-family inherit
        font-size rem(18px)
        height rem(45px)
        &.has-error
            border-color #f04242
    textarea
        height rem(200px)
    span
        display block
        font-size rem(14px)
        color #f04242
        padding-bottom rem(10px)
    button[type="submit"]
        display block
        padding rem(14px) rem(39px) rem(14px) rem(39px)
        color #ffffff
        background themeColor
        font-size rem(18px)
        width 100%
        border 1px solid darken(themeColor, 30%)
        border-width 1px 1px 3px
        margin-top rem(50px)
        margin-bottom rem(10px)
        cursor pointer
        transition all .3s
        outline none
        &:hover
            background darken(themeColor, 20%)
        +above(cut)
            padding rem(19px) rem(39px) rem(18px) rem(39px)
    [v-cloak]
        display none
